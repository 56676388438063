import { useAuthStore } from '@/stores/auth';

export default defineNuxtRouteMiddleware(() => {
  const { loggedIn } = storeToRefs(useAuthStore());

  if (!loggedIn.value) {
    // TODO: Add 'redirect' query param
    return navigateTo(
      {
        path: '/login'
      },
      { replace: true }
    );
  }
});
